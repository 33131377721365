import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CodeInput } from 'core/components/form';

import { PinCodeFormData, pinCodeSchema } from './validationSchema';

export type PinCodeFormProps = {
  onSubimit: (values: PinCodeFormData) => Promise<void>;
  renderButtons: (props: {
    isSubmitting: boolean;
    isDisabled: boolean;
  }) => ReactNode;
};

const PinCodeForm: FC<PinCodeFormProps> = ({ onSubimit, renderButtons }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<PinCodeFormData>({
    resolver: yupResolver(pinCodeSchema),
    mode: 'all',
    defaultValues: { code: '' },
  });

  const isDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit(onSubimit);

  return (
    <form className="flex flex-col" onSubmit={onSubmit}>
      <CodeInput
        name="code"
        control={control}
        componentProps={{ numInputs: 4 }}
      />
      {renderButtons({ isSubmitting, isDisabled })}
      <p className="mt-4 text-center text-[12px]">
        {t('pages.pinCodeForm.helperText')}
      </p>
    </form>
  );
};

export default PinCodeForm;
