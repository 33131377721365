import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Wrapper } from 'core/components/common';
import { CircularIndicator } from 'core/components/uikit';
import {
  withConfirmActionDialog,
  WithConfirmActionDialogSharedProps,
} from 'features/components';

import { DeleteButton, StartStopButton, Statuses } from './components';
import useEditSessionStore, { useFetchSession } from './editSessionStore';
import CreateEditSessionForm, {
  CreateEditSessionFormProps,
  transformIn,
} from '../CreateEditSessionForm';

const EditSession: FC<WithConfirmActionDialogSharedProps> = ({
  showConfirmActionDialog,
}) => {
  const { t } = useTranslation();
  const { sessionId } = useParams();
  const { data, isLoading } = useFetchSession(sessionId ?? '');

  if (isLoading) {
    return (
      <Wrapper internalClassName="flex flex-col">
        <CircularIndicator />
      </Wrapper>
    );
  }

  if (data === null) {
    return (
      <Wrapper internalClassName="flex flex-col">
        <h4 className="text-center">{t('noData.session')}</h4>
      </Wrapper>
    );
  }

  const { status, sessionName, monitorStatus } = data;
  const isBotActive = status === 'active';
  const disabled = {
    phForm: isLoading || isBotActive,
    ahForm: isLoading || isBotActive,
    rthForm: isLoading || isBotActive,
    blacklist: isLoading || isBotActive,
    submitButton: isLoading || isBotActive,
    sessionName: isLoading || isBotActive,
  };
  const defaultValues = transformIn(data);

  const onSubmit: CreateEditSessionFormProps['onSubmit'] = async (values) =>
    showConfirmActionDialog({
      onConfirm: async () => {
        try {
          await useEditSessionStore.getState().editSession(values);
        } catch (error) {
          console.error(error);
        }
      },
      title: t('components.confirmActionDialog.toDoSomethingWithSession', {
        action: t('components.confirmActionDialog.actions.edit'),
      }),
    });

  return (
    <Wrapper internalClassName="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center justify-between gap-6">
          <h2>{sessionName}</h2>
          <div className="flex flex-row items-center gap-4">
            <StartStopButton disabled={isLoading} />
            <DeleteButton disabled={isLoading} />
          </div>
        </div>
        <Statuses status={status} monitorStatus={monitorStatus} />
      </div>
      <CreateEditSessionForm
        onSubmit={onSubmit}
        disabled={disabled}
        defaultValues={defaultValues}
        actionButtonText={t('pages.editSession.action')}
      />
    </Wrapper>
  );
};

const EditSessionWithHoc = withConfirmActionDialog(EditSession);

export default EditSessionWithHoc;
