import { Action, Order, Position } from 'common/models';

export const GET_SESSION_POSITIONS = ({ id }: { id: string }): Position[] => [
  {
    id: '1',
    ticker: 'AAPL',
    type: 'short',
    quantity: 3,
    avgPrice: 300.0,
    currentSize: 300.0,
    stopLossPrice: null,
    takeProfitPrice: null,
    status: 'open',
    openDate: new Date('2024-11-12T00:20:15'),
    closeDate: null,
    currentPrice: 310.0,
    marketType: 'ahMarket',
    sessionId: id,
    locateType: 'etb',
  },
  {
    id: '2',
    ticker: 'AAPL',
    type: 'short',
    quantity: 3,
    avgPrice: 300.0,
    currentSize: 300.0,
    stopLossPrice: null,
    takeProfitPrice: null,
    status: 'closed',
    openDate: new Date('2024-11-12T00:20:15'),
    closeDate: null,
    currentPrice: 310.0,
    marketType: 'ahMarket',
    sessionId: id,
    locateType: 'etb',
  },
  {
    id: '3',
    ticker: 'AAPL',
    type: 'short',
    quantity: 3,
    avgPrice: 300.0,
    currentSize: 300.0,
    stopLossPrice: null,
    takeProfitPrice: null,
    status: 'pending',
    openDate: new Date('2024-11-12T00:20:15'),
    closeDate: null,
    currentPrice: 310.0,
    marketType: 'ahMarket',
    sessionId: id,
    locateType: 'etb',
  },
];

export const GET_POSITION_ORDERS = ({ id }: { id: string }): Order[] => [
  {
    id: '1',
    ticker: 'string',
    externalId: '1',
    status: 'executing',
    side: 'buy',
    type: 'market',
    priceType: 'market',
    entryPrice: 0,
    executedPrice: null,
    quantity: 0,
    sentDate: new Date('2024-11-12T00:20:15'),
    filledDate: new Date('2024-11-12T00:20:15'),
    updatedDate: new Date('2024-11-12T00:20:15'),
    positionId: id,
    closeTriggerType: null,
    sizeOfClosingPositionPart: null,
    token: 0,
  },
  {
    id: '2',
    ticker: 'string',
    externalId: '2',
    status: 'executing',
    side: 'buy',
    type: 'market',
    priceType: 'market',
    entryPrice: 0,
    executedPrice: null,
    quantity: 0,
    sentDate: new Date('2024-11-12T00:20:15'),
    filledDate: new Date('2024-11-12T00:20:15'),
    updatedDate: new Date('2024-11-12T00:20:15'),
    positionId: id,
    closeTriggerType: null,
    sizeOfClosingPositionPart: null,
    token: 0,
  },
];

export const ACTIONS: Action[] = [
  { date: new Date('2024-11-12T00:15:00'), type: 'STATUS', text: 'Start Bot' },
  { date: new Date('2024-11-12T00:16:00'), type: 'ALERT', text: 'GME $20.50' },
  {
    date: new Date('2024-11-12T00:17:30'),
    type: 'TRADE',
    text: 'Checking market conditions',
  },
  {
    date: new Date('2024-11-12T00:18:45'),
    type: 'ALERT',
    text: 'TSLA $650.00',
  },
  {
    date: new Date('2024-11-12T00:20:15'),
    type: 'STATUS',
    text: 'Executing Buy Order',
  },
  {
    date: new Date('2024-11-12T00:21:00'),
    type: 'ALERT',
    text: 'AAPL $120.00',
  },
  {
    date: new Date('2024-11-12T00:22:10'),
    type: 'TRADE',
    text: 'Order executed',
  },
  {
    date: new Date('2024-11-12T00:23:50'),
    type: 'STATUS',
    text: 'Monitoring trades',
  },
  {
    date: new Date('2024-11-12T00:25:20'),
    type: 'ALERT',
    text: 'MSFT $200.30',
  },
  {
    date: new Date('2024-11-12T00:27:00'),
    type: 'TRADE',
    text: 'Market volatility detected',
  },
  {
    date: new Date('2024-11-12T00:28:15'),
    type: 'STATUS',
    text: 'Adjusting strategy',
  },
  { date: new Date('2024-11-12T00:29:30'), type: 'ALERT', text: 'FB $250.75' },
  {
    date: new Date('2024-11-12T00:31:05'),
    type: 'STATUS',
    text: 'Re-evaluating conditions',
  },
  {
    date: new Date('2024-11-12T00:33:00'),
    type: 'ALERT',
    text: 'AMZN $3050.25',
  },
  {
    date: new Date('2024-11-12T00:34:45'),
    type: 'TRADE',
    text: 'Holding positions',
  },
  {
    date: new Date('2024-11-12T00:36:00'),
    type: 'STATUS',
    text: 'Updating logs',
  },
];

export const POSITIONS: Position[] = [
  ...GET_SESSION_POSITIONS({
    id: '1',
  }),
  ...GET_SESSION_POSITIONS({
    id: '2',
  }),
];

export const ORDERS: Order[] = [
  ...GET_POSITION_ORDERS({
    id: '1',
  }),
  ...GET_POSITION_ORDERS({
    id: '2',
  }),
];
