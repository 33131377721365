import { ColumnDef, PaginationState } from '@tanstack/react-table';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Action, ActionFilter } from 'common/models';
import {
  CircularIndicator,
  dateCell,
  enumCell,
  FiltersProps,
  Table,
  valueCell,
} from 'core/components/uikit';

import LastActionsFilters from './LastActionsFilters';
import { useFetchActionsWithTimer } from './useFetchActions';
import DisclosureWithTitle from '../DisclosureWithTitle';

export type LastActionsProps = {
  filters?: ActionFilter;
  title?: string;
};

const LastActions: FC<LastActionsProps> = ({ filters = {}, title }) => {
  const { t } = useTranslation();
  const { data, fetch, isLoading, args, startTimer, stopTimer } =
    useFetchActionsWithTimer(filters);

  const columns = useMemo<ColumnDef<Action>[]>(
    () => [
      {
        header: t('components.lastActionsTable.headers.type'),
        accessorKey: 'type',
        cell: enumCell(),
      },
      {
        header: t('components.lastActionsTable.headers.text'),
        accessorKey: 'text',
        cell: (props) => {
          return (
            <div className="min-w-[16rem] max-w-full whitespace-normal break-words">
              {valueCell()(props) as string}
            </div>
          );
        },
      },
      {
        header: t('components.lastActionsTable.headers.date'),
        accessorKey: 'date',
        cell: dateCell(),
      },
    ],
    [t]
  );

  const onPaginationChanged = useCallback(
    async ({ pageIndex, pageSize }: PaginationState) => {
      await fetch({
        ...(args ?? {}),
        page: pageIndex + 1,
        perPage: pageSize,
      });
    },
    [fetch, args]
  );

  const renderFilters = useCallback(
    (props: FiltersProps<Action>) => (
      <LastActionsFilters {...props} fetch={fetch} args={args} />
    ),
    [fetch, args]
  );

  const toogleTimer = useCallback(
    (open: boolean) => {
      if (open) {
        stopTimer();
      } else {
        startTimer(5000);
      }
    },
    [stopTimer, startTimer]
  );

  return (
    <DisclosureWithTitle
      title={
        <div className="flex flex-row items-center gap-2">
          <h4>{title ?? t('pages.sessionDetails.systemLogs')}</h4>
          {isLoading && <CircularIndicator size={24} />}
        </div>
      }
      onClick={toogleTimer}
    >
      <Table
        data={data?.items ?? []}
        columns={columns}
        totalCount={data?.filtered ?? 0}
        onPaginationChanged={onPaginationChanged}
        options={{
          initialState: {
            pagination: {
              pageIndex: (args?.page ?? 1) - 1,
              pageSize: args?.perPage ?? 10,
            },
          },
        }}
        filters={renderFilters}
      />
    </DisclosureWithTitle>
  );
};

export default LastActions;
