import { t } from 'i18next';
import * as yup from 'yup';

import {
  createTimeInputSchema,
  createMinMaxInputSchema,
  createNumberInputSchema,
  createDurationInputSchema,
  createRangeInputSchema,
  createSelectInputSchema,
  createRadioGroupInputSchema,
  createCheckboxGroupInputSchema,
} from 'core/components/form';

const filterParamsSchema = yup.object({
  exchanges: createCheckboxGroupInputSchema({
    name: t('pages.createSession.common.form.filterParams.exchanges.label'),
    required: true,
  }),
  priceChangeFromPrevCloseRange: createMinMaxInputSchema({
    min: 0,
    max: Infinity,
    required: false,
  }),
  marketCapRange: createMinMaxInputSchema({
    min: 0,
    max: Infinity,
    required: false,
  }),
  priceRange: createMinMaxInputSchema({
    min: 0,
    max: Infinity,
    required: false,
  }),
  lastPeriod: createDurationInputSchema({
    required: false,
    name: t('pages.createSession.common.form.filterParams.lastPeriod.label'),
  }),
  currentDayVolumeRange: createMinMaxInputSchema({
    min: 0,
    max: Infinity,
    required: false,
  }),
  lastPeriodVolumeRange: createMinMaxInputSchema({
    min: 0,
    max: Infinity,
    required: false,
  }),
});

const createTriggerParamsSchema = ({ required }: { required: boolean }) =>
  yup
    .object({
      lastPeriodInterval: createDurationInputSchema({
        required: false,
        name: t(
          'pages.createSession.common.form.triggerParams.lastPeriodInterval.label'
        ),
      }),
      lastPeriodPriceChange: createNumberInputSchema({
        required: false,
        min: 1,
        max: Infinity,
        name: t(
          'pages.createSession.common.form.triggerParams.lastPeriodPriceChange.name'
        ),
      }),
      priceChangeFromPrevClose: createNumberInputSchema({
        required: false,
        min: 1,
        max: Infinity,
        name: t(
          'pages.createSession.common.form.triggerParams.priceChangeFromPrevClose.name'
        ),
      }),
    })
    .test(
      'required-or-at-least-one',
      t('errors.validation.triggers.atLeastOne'),
      function (values) {
        const {
          lastPeriodInterval,
          lastPeriodPriceChange,
          priceChangeFromPrevClose,
        } = values;

        if (!required) return true;

        const option1 =
          lastPeriodInterval != null && lastPeriodPriceChange != null;
        const option2 = priceChangeFromPrevClose != null;

        if (!option1 && !option2) {
          return false;
        }

        if (
          (lastPeriodInterval != null && lastPeriodPriceChange == null) ||
          (lastPeriodInterval == null && lastPeriodPriceChange != null)
        ) {
          return false;
        }

        return true;
      }
    );

const createOpenPositionParamsSchema = ({ required }: { required: boolean }) =>
  yup.object({
    maxPositionVolume: createNumberInputSchema({
      required,
      min: 0,
      max: Infinity,
      name: t(
        'pages.createSession.common.form.openPositionParams.maxPositionVolume.name'
      ),
    }),
    maxEntryVolume: createNumberInputSchema({
      required,
      min: 0,
      max: Infinity,
      name: t(
        'pages.createSession.common.form.openPositionParams.maxEntryVolume.name'
      ),
    }),
    openEntryInterval: createDurationInputSchema({
      required,
      name: t(
        'pages.createSession.common.form.openPositionParams.openEntryInterval.name'
      ),
    }),
    orderType: createRadioGroupInputSchema({
      name: t(
        'pages.createSession.common.form.openPositionParams.orderType.name'
      ),
      required,
    }),
    orderPriceType: createRadioGroupInputSchema({
      name: t(
        'pages.createSession.common.form.openPositionParams.orderPriceType.name'
      ),
      required,
    }),
    orderRefreshInterval: yup.lazy((_, { parent }) =>
      createDurationInputSchema({
        required: required && parent.orderType === 'limit',
        name: t(
          'pages.createSession.common.form.openPositionParams.orderRefreshInterval.name'
        ),
      })
    ),
    openEntryLimit: yup.lazy((_, { parent }) =>
      createDurationInputSchema({
        required: required && parent.orderType === 'limit',
        name: t(
          'pages.createSession.common.form.openPositionParams.openEntryLimit.name'
        ),
      })
    ),
    chaseDown: yup.lazy((_, { parent }) =>
      createNumberInputSchema({
        required: required && parent.orderType === 'limit',
        min: 0,
        max: Infinity,
        name: t(
          'pages.createSession.common.form.openPositionParams.chaseDown.name'
        ),
      })
    ),
    additionalSteps: yup.array().of(
      yup.object({
        entrySize: createNumberInputSchema({
          required,
          min: 0,
          max: Infinity,
          name: t(
            'pages.createSession.common.form.openPositionParams.additionalSteps.entrySize.name'
          ),
        }),
        triggeringPriceChange: createNumberInputSchema({
          required,
          min: 0,
          max: Infinity,
          name: t(
            'pages.createSession.common.form.openPositionParams.additionalSteps.triggeringPriceChange.name'
          ),
        }),
      })
    ),
  });

const createClosePositionParamsSchema = ({ required }: { required: boolean }) =>
  yup.object({
    stopLossParams: yup.array().of(
      yup.object({
        maxPositionVolume: createNumberInputSchema({
          required,
          min: 0,
          max: Infinity,
          name: t(
            'pages.createSession.closePositionParams.form.stopLossParams.maxPositionVolume.name'
          ),
        }),
        sizeOfClosingPositionPart: createNumberInputSchema({
          required,
          min: 0.01,
          max: 100,
          name: t(
            'pages.createSession.closePositionParams.form.stopLossParams.sizeOfClosingPositionPart.name'
          ),
        }),
      })
    ),
    takeProfitParams: yup.array().of(
      yup.object({
        triggeringPriceChange: createNumberInputSchema({
          required,
          min: 0,
          max: 99.99,
          name: t(
            'pages.createSession.closePositionParams.form.takeProfitParams.triggeringPriceChange.name'
          ),
        }),
        sizeOfClosingPositionPart: createNumberInputSchema({
          required,
          min: 0.01,
          max: 100,
          name: t(
            'pages.createSession.closePositionParams.form.takeProfitParams.sizeOfClosingPositionPart.name'
          ),
        }),
      })
    ),
    timeBasedParams: yup.array().of(
      yup.object({
        triggeringTime: createTimeInputSchema({
          required,
          name: t(
            'pages.createSession.closePositionParams.form.timeBasedParams.triggeringTime.name'
          ),
        }),
        sizeOfClosingPositionPart: createNumberInputSchema({
          required,
          min: 0.01,
          max: 100,
          name: t(
            'pages.createSession.closePositionParams.form.timeBasedParams.sizeOfClosingPositionPart.name'
          ),
        }),
        orderType: createRadioGroupInputSchema({
          name: t(
            'pages.createSession.closePositionParams.form.timeBasedParams.orderType.name'
          ),
          required,
        }),
        orderPriceType: createRadioGroupInputSchema({
          name: t(
            'pages.createSession.closePositionParams.form.timeBasedParams.orderPriceType.name'
          ),
          required,
        }),
        orderRefreshInterval: yup.lazy((_, { parent }) =>
          createDurationInputSchema({
            required: required && parent.orderType === 'limit',
            name: t(
              'pages.createSession.closePositionParams.form.timeBasedParams.orderRefreshInterval.name'
            ),
          })
        ),
        openEntryLimit: yup.lazy((_, { parent }) =>
          createDurationInputSchema({
            required: required && parent.orderType === 'limit',
            name: t(
              'pages.createSession.closePositionParams.form.timeBasedParams.openEntryLimit.name'
            ),
          })
        ),
        entriesCount: createNumberInputSchema({
          required,
          min: 0,
          max: Infinity,
          name: t(
            'pages.createSession.closePositionParams.form.timeBasedParams.entriesCount.name'
          ),
        }),
        openEntryInterval: createDurationInputSchema({
          required,
          name: t(
            'pages.createSession.closePositionParams.form.timeBasedParams.openEntryInterval.name'
          ),
        }),
        exitDelay: createDurationInputSchema({
          required: false,
          name: t(
            'pages.createSession.closePositionParams.form.timeBasedParams.exitDelay.name'
          ),
        }),
      })
    ),
  });

const marketParamsSchema = yup.object({
  active: yup.boolean().required(),
  marketHoursRange: createRangeInputSchema({
    name: 'Range',
    required: true,
  }),
  filterParams: filterParamsSchema,
  triggerParams: createTriggerParamsSchema({ required: true }),
  openPositionParams: createOpenPositionParamsSchema({ required: true }),
  closePositionParams: createClosePositionParamsSchema({ required: true }),
});

const dynamicMarketParamsSchema = yup.lazy((value) => {
  if (value?.active === false) {
    return yup.object({
      active: yup.boolean(),
      marketHoursRange: yup.mixed(),
      filterParams: yup.mixed(),
      triggerParams: yup.mixed(),
      openPositionParams: yup.mixed(),
      closePositionParams: yup.mixed(),
    });
  }

  return marketParamsSchema;
});

const sessionNameSchema = yup
  .string()
  .transform((value, originalValue) => {
    if (typeof originalValue === 'string') {
      return originalValue.trim() === '' ? null : value;
    }
    return value;
  })
  .required(
    t('errors.validation.isRequired', {
      name: t('pages.createSession.sessionName.label'),
    })
  );

export const createEditSessionSchema = yup
  .object({
    sessionName: sessionNameSchema,
    rthParams: dynamicMarketParamsSchema,
    phParams: dynamicMarketParamsSchema,
    ahParams: dynamicMarketParamsSchema,
    blacklist: createSelectInputSchema({
      name: t('pages.createSession.blacklist.name'),
      required: false,
    }),
    whitelist: createSelectInputSchema({
      name: t('pages.createSession.blacklist.name'),
      required: false,
    }),
  })
  .test(
    'at-least-one-timeperiod-active',
    t('errors.validation.timePeriod'),
    function (value) {
      const { rthParams, phParams, ahParams } = value || {};
      const isIncluded =
        rthParams?.active || phParams?.active || ahParams?.active;

      if (!isIncluded) {
        return this.createError({
          path: 'main',
          message: t('errors.validation.timePeriod'),
        });
      }

      return true;
    }
  )
  .required();

type ValidationErrors<T> = {
  [K in keyof T]?: T[K] extends object
    ? ValidationErrors<T[K]>
    : { message?: string; type?: string };
} & {
  main?: { message?: string; type?: string };
};

export type MarketParamsFormData = yup.InferType<typeof marketParamsSchema>;
export type CreateEditSessionFormData = yup.InferType<
  typeof createEditSessionSchema
>;
export type CreateEditSessionErrors =
  ValidationErrors<CreateEditSessionFormData>;
