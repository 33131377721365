import { ColumnDef, PaginationState } from '@tanstack/react-table';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Ticker, TickersFilter } from 'common/models';
import { decimalToPercent, formatBigNumber } from 'common/utils';
import {
  Table,
  enumCell,
  dateCell,
  valueCell,
  numberCell,
  CircularIndicator,
  FiltersProps,
} from 'core/components/uikit';
import { DisclosureWithTitle } from 'features/components';

import TickersFilters from './TickersFilters';
import { useFetchSessionTickers } from './useFetchSessionTickers';

export type TickersTableProps = {
  filters?: TickersFilter;
  title?: string;
};

const TickersTable: FC<TickersTableProps> = ({ filters = {}, title }) => {
  const { t } = useTranslation();
  const { data, isLoading, fetch, startTimer, stopTimer, args } =
    useFetchSessionTickers(filters);

  const columns = useMemo<ColumnDef<Ticker>[]>(
    () => [
      {
        header: t('components.tickersTable.headers.ticker'),
        accessorKey: 'ticker',
        cell: valueCell(),
      },
      {
        header: t('components.tickersTable.headers.filtersData'),
        columns: [
          {
            header: t('components.tickersTable.headers.price'),
            accessorKey: 'filtersData.price',
            cell: numberCell({ prefix: '$' }),
          },
          {
            header: t('components.tickersTable.headers.marketCap'),
            accessorKey: 'filtersData.marketCap',
            cell: numberCell({
              prefix: '$',
              handler: (value) =>
                value
                  ? `${formatBigNumber((value / 1000000).toFixed(2))}M`
                  : null,
            }),
          },
          {
            header: t('components.tickersTable.headers.currentDayVolume'),
            accessorKey: 'filtersData.currentDayVolume',
            cell: numberCell({
              prefix: '$',
              handler: (value) =>
                value ? formatBigNumber(Math.round(value).toString()) : null,
            }),
          },
          {
            header: t('components.tickersTable.headers.priceChangePercent'),
            accessorKey: 'filtersData.priceChangeFromPrevClose',
            cell: numberCell({
              suffix: '%',
              handler: (value) => decimalToPercent(value, 2),
            }),
          },
          {
            header: t('components.tickersTable.headers.lastPeriodVolume'),
            accessorKey: 'filtersData.lastPeriodVolume',
            cell: numberCell({
              prefix: '$',
              handler: (value) =>
                value ? formatBigNumber(Math.round(value).toString()) : null,
            }),
          },
          {
            header: t('components.tickersTable.headers.lastPeriodMinutes'),
            accessorKey: 'filtersData.lastPeriod',
            cell: valueCell(),
          },
          {
            header: t('components.tickersTable.headers.updatedAt'),
            accessorKey: 'filtersData.updatedAt',
            cell: dateCell(),
          },
        ],
      },
      {
        header: t('components.tickersTable.headers.triggerData'),
        columns: [
          {
            header: t('components.tickersTable.headers.prevCloseTrigger'),
            accessorKey: 'triggersData.prevCloseTrigger',
            cell: numberCell({
              suffix: '%',
              handler: (value) => decimalToPercent(value, 2),
            }),
          },
          {
            header: t('components.tickersTable.headers.lastPeriodTrigger'),
            accessorKey: 'triggersData.lastPeriodTrigger',
            cell: numberCell({
              suffix: '%',
              handler: (value) => decimalToPercent(value, 2),
            }),
          },
          {
            header: t('components.tickersTable.headers.updatedAt'),
            accessorKey: 'triggersData.updatedAt',
            cell: dateCell(),
          },
        ],
      },
      {
        header: t('components.tickersTable.headers.marketType'),
        accessorKey: 'marketType',
        cell: enumCell(),
      },
      {
        header: t('components.tickersTable.headers.listType'),
        accessorKey: 'listType',
        cell: enumCell(),
      },
    ],
    [t]
  );

  const toogleTimer = useCallback(
    (open: boolean) => {
      if (open) {
        stopTimer();
      } else {
        startTimer(5000);
      }
    },
    [stopTimer, startTimer]
  );

  const renderFilters = useCallback(
    (props: FiltersProps<Ticker>) => (
      <TickersFilters {...props} fetch={fetch} args={args} />
    ),
    [fetch, args]
  );

  const onPaginationChanged = useCallback(
    async ({ pageIndex, pageSize }: PaginationState) => {
      const newArgs = {
        ...(args ?? {}),
        page: pageIndex + 1,
        perPage: pageSize,
      };
      await fetch(newArgs);
    },
    [args]
  );

  return (
    <DisclosureWithTitle
      title={
        <div className="flex flex-row items-center gap-2">
          <h4>{title ?? t('components.tickersTable.title')}</h4>
          {isLoading && <CircularIndicator size={24} />}
        </div>
      }
      onClick={toogleTimer}
    >
      <Table
        columns={columns}
        onPaginationChanged={onPaginationChanged}
        data={data?.items ?? []}
        totalCount={data?.filtered ?? 0}
        options={{
          initialState: {
            pagination: {
              pageIndex: (args?.page ?? 1) - 1,
              pageSize: args?.perPage,
            },
          },
        }}
        filters={renderFilters}
      />
    </DisclosureWithTitle>
  );
};

export default TickersTable;
