import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EXCHANGE } from 'common/models/session';
import {
  formatBigNumber,
  formatNumber,
  unformatBigNumber,
  unformatNumber,
} from 'common/utils';
import {
  CheckboxGroupInput,
  DurationInput,
  MinMaxInput,
} from 'core/components/form';
import { DisclosureWithTitle } from 'features/components';

import { CommonInputsProps } from './types';

const FilterInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <DisclosureWithTitle
      defaultOpen={true}
      title={<h4>{t('pages.createSession.common.form.filterParams.title')}</h4>}
    >
      <CheckboxGroupInput
        control={control}
        name={`${basename}.filterParams.exchanges`}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.filterParams.exchanges.label'
          ),
          options: [
            {
              label: t(
                'pages.createSession.common.form.filterParams.exchanges.nasdaq'
              ),
              value: EXCHANGE.NASDAQ,
            },
            {
              label: t(
                'pages.createSession.common.form.filterParams.exchanges.nyse'
              ),
              value: EXCHANGE.NYSE,
            },
          ],
        }}
      />
      <MinMaxInput
        min={{
          control,
          name: `${basename}.filterParams.priceChangeFromPrevCloseRange.min`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.priceChangeFromPrevCloseRange.min'
            ),
            disabled,
            suffix: '%',
            format: formatNumber,
            unformat: unformatNumber,
          },
        }}
        max={{
          control,
          name: `${basename}.filterParams.priceChangeFromPrevCloseRange.max`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.priceChangeFromPrevCloseRange.max'
            ),
            disabled,
            suffix: '%',
            format: formatNumber,
            unformat: unformatNumber,
          },
        }}
      />
      <MinMaxInput
        min={{
          control,
          name: `${basename}.filterParams.marketCapRange.min`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.marketCapRange.min'
            ),
            disabled,
            suffix: 'M$',
            format: formatBigNumber,
            unformat: unformatBigNumber,
          },
        }}
        max={{
          control,
          name: `${basename}.filterParams.marketCapRange.max`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.marketCapRange.max'
            ),
            disabled,
            suffix: 'M$',
            format: formatBigNumber,
            unformat: unformatBigNumber,
          },
        }}
      />
      <MinMaxInput
        min={{
          control,
          name: `${basename}.filterParams.priceRange.min`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.priceRange.min'
            ),
            disabled,
            suffix: '$',
            format: formatBigNumber,
            unformat: unformatBigNumber,
          },
        }}
        max={{
          control,
          name: `${basename}.filterParams.priceRange.max`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.priceRange.max'
            ),
            disabled,
            suffix: '$',
            format: formatBigNumber,
            unformat: unformatBigNumber,
          },
        }}
      />
      <MinMaxInput
        min={{
          control,
          name: `${basename}.filterParams.currentDayVolumeRange.min`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.currentDayVolumeRange.min'
            ),
            disabled,
            suffix: '$',
            format: formatBigNumber,
            unformat: unformatBigNumber,
          },
        }}
        max={{
          control,
          name: `${basename}.filterParams.currentDayVolumeRange.max`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.currentDayVolumeRange.max'
            ),
            disabled,
            suffix: '$',
            format: formatBigNumber,
            unformat: unformatBigNumber,
          },
        }}
      />
      <MinMaxInput
        min={{
          control,
          name: `${basename}.filterParams.lastPeriodVolumeRange.min`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.lastPeriodVolumeRange.min'
            ),
            disabled,
            suffix: '$',
            format: formatBigNumber,
            unformat: unformatBigNumber,
          },
        }}
        max={{
          control,
          name: `${basename}.filterParams.lastPeriodVolumeRange.max`,
          componentProps: {
            label: t(
              'pages.createSession.common.form.filterParams.lastPeriodVolumeRange.max'
            ),
            disabled,
            suffix: '$',
            format: formatBigNumber,
            unformat: unformatBigNumber,
          },
        }}
      />
      <DurationInput
        control={control}
        name={`${basename}.filterParams.lastPeriod`}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.filterParams.lastPeriod.label'
          ),
        }}
      />
    </DisclosureWithTitle>
  );
};

export default FilterInputs;
