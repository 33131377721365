import { ColumnDef, PaginationState } from '@tanstack/react-table';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskFilter, Task } from 'common/models';
import { decimalToPercent } from 'common/utils';
import {
  Table,
  enumCell,
  valueCell,
  numberCell,
  CircularIndicator,
} from 'core/components/uikit';

import CancelTaskButton from './CancelTaskButton';
import { useFetchTasksWithTimer } from './useFetchTasks';
import DisclosureWithTitle from '../DisclosureWithTitle';

export type TasksTableProps = {
  filters?: TaskFilter;
  title?: string;
};

export const TasksTable = memo(({ filters = {}, title }: TasksTableProps) => {
  const { t } = useTranslation();
  const { data, isLoading, fetch, args } = useFetchTasksWithTimer(filters);

  const refetch = useCallback(async () => {
    if (args) await fetch(args);
  }, [args]);

  const columns = useMemo<ColumnDef<Task>[]>(
    () => [
      {
        header: t('components.tasksTable.headers.status'),
        accessorKey: 'status',
        cell: enumCell(),
      },
      {
        id: 'cancel-order',
        header: t('components.tasksTable.headers.cancel'),
        cell: ({ row }) => {
          const { id, status } = row.original;
          const showCancelButton = status === 'scheduled';

          if (showCancelButton) {
            return <CancelTaskButton id={id ?? ''} refetchTable={refetch} />;
          }

          return '-';
        },
      },
      {
        header: t('components.tasksTable.headers.triggeringTime'),
        accessorKey: 'closeParams.triggeringTime',
        cell: valueCell(),
      },
      {
        header: t('components.tasksTable.headers.sizeOfClosingPositionPart'),
        accessorKey: 'closeParams.sizeOfClosingPositionPart',
        cell: numberCell({
          suffix: '%',
          handler: (value) => decimalToPercent(value, 2),
        }),
      },
      {
        header: t('components.tasksTable.headers.orderType'),
        accessorKey: 'closeParams.orderType',
        cell: enumCell(),
      },
      {
        header: t('components.tasksTable.headers.orderPriceType'),
        accessorKey: 'closeParams.orderPriceType',
        cell: enumCell(),
      },
      {
        header: t('components.tasksTable.headers.entriesCount'),
        accessorKey: 'closeParams.entriesCount',
        cell: numberCell({ fractionDigits: 0 }),
      },
      {
        header: t('components.tasksTable.headers.openEntryInterval'),
        accessorKey: 'closeParams.openEntryInterval',
        cell: valueCell(),
      },
      {
        header: t('components.tasksTable.headers.orderRefreshInterval'),
        accessorKey: 'closeParams.orderRefreshInterval',
        cell: valueCell(),
      },
      {
        header: t('components.tasksTable.headers.openEntryLimit'),
        accessorKey: 'closeParams.openEntryLimit',
        cell: valueCell(),
      },
      {
        header: t('components.tasksTable.headers.exitDelay'),
        accessorKey: 'closeParams.exitDelay',
        cell: valueCell(),
      },
    ],
    [t, refetch]
  );

  const onPaginationChanged = useCallback(
    async ({ pageIndex, pageSize }: PaginationState) => {
      const newArgs = {
        ...(args ?? {}),
        page: pageIndex + 1,
        perPage: pageSize,
      };
      await fetch(newArgs);
    },
    [args]
  );

  return (
    <DisclosureWithTitle
      defaultOpen={true}
      title={
        <div className="flex flex-row items-center gap-2">
          <h4>{title ?? t('components.tasksTable.title')}</h4>
          {isLoading && <CircularIndicator size={24} />}
        </div>
      }
    >
      <Table
        data={data?.items ?? []}
        columns={columns}
        totalCount={data?.filtered ?? 0}
        onPaginationChanged={onPaginationChanged}
        options={{
          initialState: {
            pagination: {
              pageIndex: (args?.page ?? 1) - 1,
              pageSize: args?.perPage,
            },
          },
        }}
      />
    </DisclosureWithTitle>
  );
});

TasksTable.displayName = 'TasksTable';

export default TasksTable;
