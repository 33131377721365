import React, { FC } from 'react';
import { UseControllerProps, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  formatedTimeToMinutesFromStartOfDay,
  minutesFromStartOfDayToFormatedTime,
} from 'common/utils';
import { CheckboxInput, RangeInput } from 'core/components/form';

import CommonInputs from './CommonInputs';
import { CreateEditSessionFormData } from '../validationSchema';

export type RthParametersProps = Pick<
  UseControllerProps<CreateEditSessionFormData>,
  'control'
> & {
  disabled?: boolean;
};

const RthParameters: FC<RthParametersProps> = ({ control, disabled }) => {
  const { t } = useTranslation();
  const isActive = useWatch({
    control,
    name: 'rthParams.active',
  });
  const formDisabled = disabled || !isActive;

  return (
    <div className="flex flex-1 flex-col gap-3">
      <div className="flex items-center gap-3">
        <h4>{t('pages.createSession.rth.title')}</h4>
        <CheckboxInput
          control={control}
          name="rthParams.active"
          componentProps={{ disabled }}
        />
      </div>
      <div className={`flex flex-col gap-3 ${isActive ? '' : 'hidden'}`}>
        <RangeInput
          componentProps={{
            label: t('pages.createSession.rth.form.marketHoursRange.label'),
            min: '9:30 AM',
            max: '4:00 PM',
            step: 5,
            disabled: formDisabled,
            renderLabel: minutesFromStartOfDayToFormatedTime,
            parseValue: formatedTimeToMinutesFromStartOfDay,
            formatValue: minutesFromStartOfDayToFormatedTime,
          }}
          control={control}
          name="rthParams.marketHoursRange"
        />
        <CommonInputs
          control={control}
          disabled={formDisabled}
          basename="rthParams"
        />
      </div>
    </div>
  );
};

export default RthParameters;
