import { ColumnDef, PaginationState } from '@tanstack/react-table';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Position, PositionFilter } from 'common/models';
import {
  Table,
  dateCell,
  enumCell,
  valueCell,
  numberCell,
  ExpandProps,
  CircularIndicator,
  FiltersProps,
} from 'core/components/uikit';

import PositionFilters from './PositionFilters';
import { useFetchPositions } from './useFetchPositions';
import DisclosureWithTitle from '../DisclosureWithTitle';
import { OrdersTable } from '../OrdersTable';
import { TasksTable } from '../TasksTable';

export type PositionTableProps = {
  filters?: PositionFilter;
  title?: string;
};

export const PositionTableExpand = ({ row }: ExpandProps<Position>) => {
  return (
    <div className="flex flex-col gap-4 px-6 py-4">
      <OrdersTable filters={{ positionId: row.original.id ?? '' }} />
      <TasksTable filters={{ positionId: row.original.id ?? '' }} />
    </div>
  );
};

export const PositionTable: FC<PositionTableProps> = ({
  filters = {},
  title,
}) => {
  const { t } = useTranslation();
  const { data, isLoading, fetch, startTimer, stopTimer, args } =
    useFetchPositions(filters);

  const columns = useMemo<ColumnDef<Position>[]>(
    () => [
      {
        header: t('components.positionsTable.headers.tiker'),
        accessorKey: 'ticker',
        cell: valueCell(),
      },
      {
        header: t('components.positionsTable.headers.locateType'),
        accessorKey: 'locateType',
        cell: enumCell(),
      },
      {
        header: t('components.positionsTable.headers.openDate'),
        accessorKey: 'openDate',
        cell: dateCell(),
      },
      {
        header: t('components.positionsTable.headers.closeDate'),
        accessorKey: 'closeDate',
        cell: dateCell(),
      },
      {
        header: t('components.positionsTable.headers.avgPrice'),
        accessorKey: 'avgPrice',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.positionsTable.headers.currentPrice'),
        accessorKey: 'currentPrice',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.positionsTable.headers.stopLossPrice'),
        accessorKey: 'stopLossPrice',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.positionsTable.headers.takeProfitPrice'),
        accessorKey: 'takeProfitPrice',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.positionsTable.headers.type'),
        accessorKey: 'type',
        cell: enumCell(),
      },
      {
        header: t('components.positionsTable.headers.status'),
        accessorKey: 'status',
        cell: enumCell(),
      },
      {
        header: t('components.positionsTable.headers.marketType'),
        accessorKey: 'marketType',
        cell: enumCell(),
      },
      {
        header: t('components.positionsTable.headers.quantity'),
        accessorKey: 'quantity',
        cell: valueCell(),
      },
      {
        header: t('components.positionsTable.headers.currentSize'),
        accessorKey: 'currentSize',
        cell: numberCell({ prefix: '$' }),
      },
    ],
    [t]
  );

  const onPaginationChanged = useCallback(
    async ({ pageIndex, pageSize }: PaginationState) => {
      const newArgs = {
        ...(args ?? {}),
        page: pageIndex + 1,
        perPage: pageSize,
      };
      await fetch(newArgs);
    },
    [args]
  );

  const renderFilters = useCallback(
    (props: FiltersProps<Position>) => (
      <PositionFilters {...props} fetch={fetch} args={args} />
    ),
    [fetch, args]
  );

  const toogleTimer = useCallback(
    (open: boolean) => {
      if (open) {
        stopTimer();
      } else {
        startTimer(5000);
      }
    },
    [stopTimer, startTimer]
  );
  return (
    <DisclosureWithTitle
      title={
        <div className="flex flex-row items-center gap-2">
          <h4>{title ?? t('components.positionsTable.title')}</h4>
          {isLoading && <CircularIndicator size={24} />}
        </div>
      }
      onClick={toogleTimer}
    >
      <Table
        columns={columns}
        onPaginationChanged={onPaginationChanged}
        data={data?.items ?? []}
        expand={PositionTableExpand}
        totalCount={data?.filtered ?? 0}
        options={{
          initialState: {
            pagination: {
              pageIndex: (args?.page ?? 1) - 1,
              pageSize: args?.perPage,
            },
          },
        }}
        filters={renderFilters}
      />
    </DisclosureWithTitle>
  );
};

export default PositionTable;
