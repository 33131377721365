import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularIndicator } from 'core/components/uikit';
import { DisclosureWithTitle, SessionStatuses } from 'features/components';

import JsonTreeViewer from './JsonTreeViewer';
import { useFetchSessionDetails } from '../../useFetchSessionDetails';

export type SessionParamsProps = {
  sessionId: string;
};

const SessionParams: FC<SessionParamsProps> = ({ sessionId }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchSessionDetails({
    sessionId,
  });

  const {
    status,
    monitorStatus,
    sessionName,
    ahParams,
    rthParams,
    phParams,
    blacklist,
  } = data ?? {};
  const params = { phParams, rthParams, ahParams, blacklist };

  return (
    <Fragment>
      <div className="flex flex-row items-center gap-2">
        <h2>{sessionName}</h2>
        {isLoading && <CircularIndicator size={24} />}
      </div>

      <div className="flex flex-col gap-1">
        <SessionStatuses
          status={status ?? null}
          monitorStatus={monitorStatus ?? null}
        />
      </div>

      <DisclosureWithTitle
        title={
          <div className="flex flex-row items-center gap-2">
            <h4>{t('pages.sessionDetails.sessionParams')}</h4>
            {isLoading && <CircularIndicator size={24} />}
          </div>
        }
      >
        <JsonTreeViewer data={params} />
      </DisclosureWithTitle>
    </Fragment>
  );
};

export default SessionParams;
